*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}


:root{
  --primaryColor: #3E4095;
  --secondaryColor:#D1EEFE;
  --thersCoror:#172554;
  --mainHeading:#1C2E9E;
  --subHeading:#7E87BF;
  --stepCart1:#362465;
  --setpCard2:#561af0;
}

.is-fixed  {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  /* background: var(--card-bg); */
  z-index: 99;
  transition: all 0.5s;
   }

.usercardHeading{
  background-color: rgb(59, 130, 237);

}
.primaryColor{
  background-color: var(--primaryColor);
}
.secondaryColor{
  background-color: var(--secondaryColor);
}
/* .top-header {
  animation: slideRight 12s infinite;
} */

.custom-shadow {
  box-shadow: 0 -6px 10px -6px rgba(0, 0, 0, 0.5), 0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.text-Gradient-child-cho {
    color: transparent;
    font-weight: bold;
    background: linear-gradient(to right, #C46600,#990002, #900824);
    -webkit-background-clip: text;
    background-clip: text;
  }

  /* ************avatar Editor*********** */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slider {
    margin: 20px 0;
    width: 80%;
}

.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background: red;
    color: white;
}

.save-button {
    background: blue;
    color: white;
}

.add-icon {
    font-size: 50px;
    cursor: pointer;
}

.img-container {
    margin-top: 20px;
    text-align: center;
}

.container {
    text-align: center;
}

  /* *************end************ */

/* @keyframes slideRight {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
} */





/* @media (max-width: 640px) {
  @keyframes slideRight {
    0% {
      transform: translateX(calc(-110% + 90vw));
    }
    50% {
      transform: translateX(calc(110% - 90vw));
    }
    100% {
      transform: translateX(calc(-110% + 90vw));
    }
  }
  .top-header {
    animation: slideRight 5s infinite; /* Set animation duration for small screens */
  /* }
} */ 


/* @keyframes slideRight {
  0% {
    transform: translateX(calc(-110% + 90vw));
  }
  50% {
    transform: translateX(calc(110% - 90vw));
  }
  100% {
    transform: translateX(calc(-110% + 90vw));
  }
} */

/* form */
.required::before {
  content: "*";
  color: red;
  margin-right: 5px;
  font-size: 1.2rem
}



@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');



* {
  box-sizing: border-box;
}



.card {
  background-color: rgb(58, 93, 199);
  color: #b3b8cd;
  border-radius: 5px;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 10px 20px -10px rgba(0, 0, 0, .75);
}

.card-header {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
  margin-bottom: 40px;
  border-radius: 5px 5px 0 0;
  padding: 0 15px;
  width: 100%;

}

.card-photo {
  height: 110px;
  width: 110px;
  /* margin-top: -100px; */
  border-radius: 50%;
  /* border: 1px solid #1f1a32; */
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #EA9F39; */
  overflow: hidden;
  transform: translateY(calc(50% + 40px));
}

/* .card-photo img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  border-radius: 50%;
} */

.card-body {
  padding: 0 15px 15px;
  text-align: center;
}

.card-name {
  font-size: 20px;
  font-weight: 700;
  margin: 30px 0 0 135px;
}

.card-description {
  font-size: 16px;
  line-height: 21px;
  margin-top: 35px;
}

.card-button {
  margin: 10px 0 40px;
}

.card-button button:first-of-type {
  margin-right: 10px;
}

.btn {
  display: inline-block;
  padding: 10px 25px;
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  transition: all .5s ease;
  cursor: pointer;
}

.btn-primary,
.btn-outline-primary {
  border: 1px solid #03bfbc;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #231e39;
  background-color: #03bfbc;
}

.btn-primary:hover,
.btn-outline-primary {
  background-color: transparent;
  color: #03bfbc;
}

.card-social {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  list-style: none;
}

.card-social li {
  padding-right: 20px;
  transition: all .5s ease;
}

.card-social li:last-of-type {
  padding-right: 0;
}

.card-social li a {
  font-size: 20px;
  color: #b3b8cd;
}

.card-social li a:hover {
  color: #03bfbc;
}
.HeaderBorder-color {
  background: rgb(207, 32, 23);
  background: linear-gradient(54deg, rgba(207, 32, 23, 1) 26%, rgba(235, 129, 30, 1) 100%);
}

.member-inner-card{
  background: rgb(176,178,181);
background: radial-gradient(circle, rgba(176,178,181,1) 0%, rgba(220,222,223,1) 2%, rgba(176,178,181,1) 49%);
}
.member-card-heading{
  background: rgb(216,217,218);
background: radial-gradient(circle, rgba(216,217,218,1) 0%, rgba(244,244,244,1) 2%, rgba(216,217,218,1) 49%);
}
.commeetti-card-btn-gb{
  background: rgb(216,217,218);
background: radial-gradient(circle, rgba(216,217,218,1) 0%, rgba(244,244,244,1) 2%, rgba(216,217,218,1) 49%);
}
.login-card-bg{
  background: rgb(216,217,218);
background: radial-gradient(circle, rgba(216,217,218,1) 0%, rgba(244,244,244,1) 1%, rgba(216,217,218,1) 46%);
}

.comittee-card-bg-color-top{
  background: rgb(254,235,20);
background: -moz-radial-gradient(circle, rgba(254,235,20,1) 0%, rgba(245,142,50,1) 100%);
background: -webkit-radial-gradient(circle, rgba(254,235,20,1) 0%, rgba(245,142,50,1) 100%);
background: radial-gradient(circle, rgba(254,235,20,1) 0%, rgba(245,142,50,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#feeb14",endColorstr="#f58e32",GradientType=1);
}
.comittee-card-bg-color-bottom{
  background: rgb(18,144,213);
background: -moz-radial-gradient(circle, rgba(18,144,213,1) 28%, rgba(50,86,167,1) 100%);
background: -webkit-radial-gradient(circle, rgba(18,144,213,1) 28%, rgba(50,86,167,1) 100%);
background: radial-gradient(circle, rgba(18,144,213,1) 28%, rgba(50,86,167,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1290d5",endColorstr="#3256a7",GradientType=1);
}
.doctore-card-bg-color-top{
  background: rgb(6,161,227);
background: radial-gradient(circle, rgba(6,161,227,1) 26%, rgba(62,65,150,1) 100%);
}
.doctor-card-bg-color-bottom{
  background: rgb(229,238,242);
background: radial-gradient(circle, rgba(229,238,242,1) 26%, rgba(41,188,241,1) 100%);
}
.metrimony-card-colone::before{
  content: ':';
  color: white;
  margin-right: 10px;
  font: bolder;
  font-size: 16px;

}
.metrimony-card-bg-male-head{
background: rgb(237,50,55);
background: radial-gradient(circle, rgba(237,50,55,1) 32%, rgba(237,50,55,1) 100%);
}

.metrimony-card-bg-male-body{
  background: rgb(220,51,54);
background: radial-gradient(circle, rgba(220,51,54,1) 32%, rgba(76,52,53,1) 100%);
}
.metrimony-card-bg{
  background: rgb(209,41,129);
background: -moz-radial-gradient(circle, rgba(209,41,129,1) 32%, rgba(79,51,65,1) 100%);
background: -webkit-radial-gradient(circle, rgba(209,41,129,1) 32%, rgba(79,51,65,1) 100%);
background: radial-gradient(circle, rgba(209,41,129,1) 32%, rgba(79,51,65,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d12981",endColorstr="#4f3341",GradientType=1);
}
.metrimony-card-bg-bottom{
  background: rgb(216,217,218);
background: radial-gradient(circle, rgba(216,217,218,1) 0%, rgba(244,244,244,1) 1%, rgba(216,217,218,1) 46%);
}
.blood-card-header{
  background: rgb(228,52,57);
background: -moz-radial-gradient(circle, rgba(228,52,57,1) 32%, rgba(89,73,75,1) 100%);
background: -webkit-radial-gradient(circle, rgba(228,52,57,1) 32%, rgba(89,73,75,1) 100%);
background: radial-gradient(circle, rgba(228,52,57,1) 32%, rgba(89,73,75,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e43439",endColorstr="#59494b",GradientType=1);
}
.member-card-bg-bottom{
  background: rgb(216,217,218);
  background: radial-gradient(circle, rgba(216,217,218,1) 0%, rgba(244,244,244,1) 1%, rgba(216,217,218,1) 46%);
}
.landing_page_gradian{
  background: rgb(253,230,22);
background: radial-gradient(circle, rgba(253,230,22,1) 32%, rgba(248,167,42,1) 100%);
}
.shadow-all-sides {
  background-color: white;
  /* border-radius: 10px; */
  /* padding: 20px; */
  /* margin: 20px; */
  box-shadow: 0 4px 8px rgba(71, 71, 71, 0.1), /* Top light shadow */
              0 6px 20px rgba(71, 71, 71, 0.2), /* Bottom deep shadow */
              0 10px 10px rgba(71, 71, 71, 0.1), /* Bottom spread shadow */
              4px 0 10px rgba(71, 71, 71, 0.05), /* Right shadow */
             -4px 0 10px rgba(71, 71, 71, 0.05); /* Left shadow */
}
.gatividhi-card-heading{
  background: rgb(216,49,86);
background: -moz-radial-gradient(circle, rgba(216,49,86,1) 32%, rgba(76,52,53,1) 100%);
background: -webkit-radial-gradient(circle, rgba(216,49,86,1) 32%, rgba(76,52,53,1) 100%);
background: radial-gradient(circle, rgba(216,49,86,1) 32%, rgba(76,52,53,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d83156",endColorstr="#4c3435",GradientType=1);
}


